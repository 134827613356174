<template>
  <!-- 改了 -->
  <div class="main" :class="show ? 'h5' : ''">
    <div class="htop">
      <mtop />
    </div>
    <div class="banner">
      <img
        src="../assets/sdImages/party/41.png"
        alt="szzc"
        class="simg"
      />
    </div>
    <!-- <div ref="mySwiper" class="swiper-container banner" id="banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="../assets/sdImages/party/Maskgroup.png" alt="" class="simg" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/sdImages/party/Maskgroup.png" alt="" class="simg" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/sdImages/party/Maskgroup.png" alt="" class="simg" />
        </div>
        <div class="swiper-slide">
          <img src="../assets/sdImages/party/Maskgroup.png" alt="" class="simg" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div> -->
    <!-- <div class="banner">
			<div class="banimg">
				<div class="title">善达信息-国内领先的区块链技术服务商</div>
				<div class="tip">让技术更可信，让营销更简单</div>
			</div>
		</div> -->

    <div class="main_box jianjie">
      <div class="abg-radio" id="top-btns">
        <div
          class="abg-nav"
          v-for="(item, index) in jqNewsBtns"
          :key="item.id"
          :class="{ active: current2 == index }"
          @click="(current2 = index), getScroll()"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="some-line"></div>
      <div class="ss-box" id="scrollGsjsdd">
        <div class="btop">
          <div class="title">善心</div>
          <div class="tips">
            {{ desc }}
          </div>
        </div>
        <div class="box1">
          <div ref="mySwiperddd" class="swiper-container" id="detail1-1">
            <div class="swiper-wrapper">
              <template v-if="heartLeftTop.length > 0">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in heartLeftTop"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
            <div class="swiper-pagination page-detail1-1"></div>
          </div>
          <div ref="mySwiper" class="swiper-container" id="detail1-2">
            <div class="swiper-wrapper">
              <template v-if="heartRightTop.length > 0">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in heartRightTop"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList3"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
            <div class="swiper-pagination page-detail1-2"></div>
          </div>
          <div ref="mySwiper" class="swiper-container" id="detail1-3">
            <div class="swiper-wrapper">
              <template v-if="heartLeftBottom.length > 0">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in heartLeftBottom"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList2"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
            <div class="swiper-pagination page-detail1-3"></div>
          </div>
          <div ref="mySwiper" class="swiper-container" id="detail1-4">
            <div class="swiper-wrapper">
              <template v-if="heartRightBottom.length > 0">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in heartRightBottom"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList4"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
            </div>
            <div class="swiper-pagination page-detail1-4"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_box" id="scrollFzlcdd">
      <div class="btop">
        <div class="title">善学</div>
        <div class="tips">
          {{ desc2 }}
        </div>
      </div>
      <div class="box2">
        <div class="out-swiper-container">
          <div
            class="swiper-container swiper-container1"
            id="imgSwiper"
            ref="myimgSwiper"
          >
            <!-- <div class="Gtitle">合作伙伴</div> -->
            <div class="swiper-wrapper">
              <template v-if="learnings.length > 0">
                <div
                  class="imgBox swiper-slide"
                  v-for="(item, index) in learnings"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList5"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
              <template v-else> </template>
            </div>
            <!-- 设置左右按钮 -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_box shanxing" id="scrollRyzsdd">
      <div class="btop">
        <div class="title">善行</div>
        <div class="tips">
          {{ desc3 }}
        </div>
      </div>
      <div class="box1">
        <div ref="mySwiper" class="swiper-container" id="detail2-1">
          <div class="swiper-wrapper">
            <template v-if="moveLeftTop.length > 0">
              <div
                class="swiper-slide"
                v-for="(item, index) in moveLeftTop"
                :key="index"
              >
                <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                <el-image
                  :src="item.thumbnailUrl"
                  fit="cover"
                  :preview-src-list="previewList6"
                  :initial-index="index"
                >
                </el-image>
                <div class="swiper-pagination-text">
                  {{ item.description }}
                </div>
              </div>
            </template>
          </div>
          <div class="swiper-pagination page-detail2-1"></div>
        </div>
        <div ref="mySwiper" class="swiper-container" id="detail2-2">
          <div class="swiper-wrapper">
            <template v-if="moveRightTop.length > 0">
              <div
                class="swiper-slide"
                v-for="(item, index) in moveRightTop"
                :key="index"
              >
                <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                <el-image
                  :src="item.thumbnailUrl"
                  fit="cover"
                  :preview-src-list="previewList8"
                  :initial-index="index"
                >
                </el-image>
                <div class="swiper-pagination-text">
                  {{ item.description }}
                </div>
              </div>
            </template>
          </div>
          <div class="swiper-pagination page-detail2-2"></div>
        </div>
        <div ref="mySwiper" class="swiper-container" id="detail2-3">
          <div class="swiper-wrapper">
            <template v-if="moveLeftBottom.length > 0">
              <div
                class="swiper-slide"
                v-for="(item, index) in moveLeftBottom"
                :key="index"
              >
                <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                <el-image
                  :src="item.thumbnailUrl"
                  fit="cover"
                  :preview-src-list="previewList7"
                  :initial-index="index"
                >
                </el-image>
                <div class="swiper-pagination-text">
                  {{ item.description }}
                </div>
              </div>
            </template>
          </div>
          <div class="swiper-pagination page-detail2-3"></div>
        </div>
        <div ref="mySwiper" class="swiper-container" id="detail2-4">
          <div class="swiper-wrapper">
            <template v-if="moveRightBottom.length > 0">
              <div
                class="swiper-slide"
                v-for="(item, index) in moveRightBottom"
                :key="index"
              >
                <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                <el-image
                  :src="item.thumbnailUrl"
                  fit="cover"
                  :preview-src-list="previewList9"
                  :initial-index="index"
                >
                </el-image>
                <div class="swiper-pagination-text">
                  {{ item.description }}
                </div>
              </div>
            </template>
          </div>
          <div class="swiper-pagination page-detail2-4"></div>
        </div>
      </div>
    </div>
    <div class="about_box ts-box" id="scrollDlwzdd">
      <div class="btop2">
        <div class="title">善新</div>
        <div class="tips">
          {{ desc4 }}
        </div>
      </div>
      <div class="box-ts">
        <div class="out-swiper-container">
          <div
            class="swiper-container swiper-container1"
            id="imgSwiper2"
            ref="myimgSwiper"
          >
            <!-- <div class="Gtitle">合作伙伴</div> -->
            <div class="swiper-wrapper">
              <template v-if="newsSwipers.length > 0">
                <div
                  class="imgBox swiper-slide"
                  v-for="(item, index) in newsSwipers"
                  :key="index"
                >
                  <!-- <img :src="item.thumbnailUrl" alt="" /> -->
                  <el-image
                    :src="item.thumbnailUrl"
                    fit="cover"
                    :preview-src-list="previewList10"
                    :initial-index="index"
                  >
                  </el-image>
                  <div class="swiper-pagination-text">
                    {{ item.description }}
                  </div>
                </div>
              </template>
              <template v-else> </template>
            </div>
            <!-- 设置左右按钮 -->
            <div class="swiper-button-prev" id="btn-prev"></div>
            <div class="swiper-button-next" id="btn-next"></div>
          </div>
        </div>
      </div>
    </div>

    <mbottom />
  </div>
</template>

<script>
import { connectTron } from "@/api/Public";
import mtop from "../components/top.vue";
import mbottom from "../components/bottom.vue";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  data() {
    return {
      heightList: [],
      center: { lng: 111, lat: 111 }, //lng代表纬度 lat代表经度
      zoom: 19,
      firstIndex: 1,
      show: false,
      list: [],
      swiperVerticalData: [
        // {
        //   id: 1,
        //   name: "1合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 2,
        //   name: "2合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 3,
        //   name: "3合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 4,
        //   name: "4合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 5,
        //   name: "5合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 6,
        //   name: "6合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 7,
        //   name: "7合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 8,
        //   name: "8合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 9,
        //   name: "9合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 10,
        //   name: "10合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
        // {
        //   id: 11,
        //   name: "11合肥市人大常委会副主任、蜀山区委书记王...",
        //   time: "11-12",
        // },
      ],
      swiperList: [],
      swiperList2: [],
      list2: [],
      jqNewsBtns: [
        { id: "1", name: "善心" },
        { id: "2", name: "善学" },
        { id: "3", name: "善行" },
        { id: "4", name: "善新" },
      ],
      current2: 0,
      someId1: null,
      someId2: null,
      someId3: null,
      someId4: null,
      distanceTop2: 0,
      distanceTop3: 0,
      distanceTop4: 0,
      desc: "",
      desc2: "",
      desc3: "",
      desc4: "",
      // 善心
      heartLeftTop: [],
      previewList: [],
      heartLeftBottom: [],
      previewList2: [],
      heartRightTop: [],
      previewList3: [],
      heartRightBottom: [],
      previewList4: [],
      // 善学
      learnings: [],
      previewList5: [],
      // 善行
      moveLeftTop: [],
      previewList6: [],
      moveLeftBottom: [],
      previewList7: [],
      moveRightTop: [],
      previewList8: [],
      moveRightBottom: [],
      previewList9: [],
      // 善新
      newsSwipers: [],
      previewList10: [],
    };
  },

  components: {
    mtop,
    mbottom,
  },

  mounted() {
    // 善心
    this.someId1 = document.getElementById("scrollGsjsdd");
    // 善学
    this.someId2 = document.getElementById("scrollFzlcdd");
    // 善行
    this.someId3 = document.getElementById("scrollRyzsdd");
    // 善新
    this.someId4 = document.getElementById("scrollDlwzdd");

    this.distanceTop2 =
      this.someId2.getBoundingClientRect().top + window.pageYOffset - 200;
    this.distanceTop3 =
      this.someId3.getBoundingClientRect().top + window.pageYOffset - 200;
    this.distanceTop4 =
      this.someId4.getBoundingClientRect().top + window.pageYOffset - 200;
    window.addEventListener("scroll", this.handleScroll);
    if (this._isMobile()) {
      this.show = true;
    } else {
      this.show = this.$store.getters.phone;
    }
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    // "$store.getters.sid": function (val) {
    //   this.handleFiexdToolItem(val);
    // },
    "$store.getters.phone": function (val) {
      this.show = val;
    },
    "$route.query.current": function (val) {
      this.current2 = val;
      this.$nextTick(() => {
        this.getScroll();
      });
    },
    deep: true,
    immediate: true,
  },
  created() {
    this.current2 = this.$route.query.current;
    this.$nextTick(() => {
      this.getScroll();
    });
    // 获取党建信息
    // 善心
    this.getList();
    // 善学
    this.getList2();
    // 善行
    this.getList3();
    // 善新
    this.getList4();
  },
  methods: {
    getScroll() {
      if (this.current2 == 0) {
        this.someId1.scrollIntoView({ behavior: "smooth" });
      } else if (this.current2 == 1) {
        this.someId2.scrollIntoView({ behavior: "smooth" });
      } else if (this.current2 == 2) {
        this.someId3.scrollIntoView({ behavior: "smooth" });
      } else {
        this.someId4.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScroll() {
      //滚动头部背景变色
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log("scrollTop---->", scrollTop);

      if (scrollTop >= 647) {
        document.getElementById("top-btns").style =
          "z-index:999;position:fixed;top:0;background: #ffffff;border: 1px solid hsla(0, 0%, 100%, .2);box-shadow: 0 8px 32px rgba(28, 67, 125, .06);";
        if (scrollTop < this.distanceTop2) {
          this.current2 = 0;
        }
        if (scrollTop >= this.distanceTop2) {
          this.current2 = 1;
        }
        if (scrollTop >= this.distanceTop3) {
          this.current2 = 2;
        }
        if (scrollTop >= this.distanceTop4) {
          this.current2 = 3;
        }
      }

      if (scrollTop < 647) {
        document.getElementById("top-btns").style =
          "position:static;top:0;border: none;box-shadow: none;";
      }
    },
    toteamPage(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        path: "teamdetal",
        query: {
          detail: row.id,
        },
      });
    },
    toPagedj(row) {
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "teamdetal",
        params: {
          detail: row,
        },
      });
    },
    toNews(item) {
      // sessionStorage.setItem("conid", 19);
      // this.toPage("/teamdetal");
      window.open(item.link, "_blank");
    },
    initBannerSwiper() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#banner", {
        loop: true, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //分页器按钮可点击
        },
        // autoplay: false,
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },

    // 善心轮播图1
    detail11() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail1-1", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail1-1",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善心轮播图2
    detail12() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail1-2", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail1-2",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善心轮播图3
    detail13() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail1-3", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail1-3",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善心轮播图4
    detail14() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail1-4", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail1-4",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },

    // 善行轮播图1
    detail21() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail2-1", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail2-1",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善行轮播图2
    detail22() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail2-2", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail2-2",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善行轮播图3
    detail23() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail2-3", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail2-3",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },
    // 善行轮播图4
    detail24() {
      // eslint-disable-next-line
      let vueComponent = this; //获取vue组件实例
      //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
      new Swiper("#detail2-4", {
        loop: false, // 循环模式选项
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //分页器
        pagination: {
          el: ".page-detail2-4",
          clickable: true, //分页器按钮可点击
        },
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      });
    },

    initImgSwiper() {
      new Swiper("#imgSwiper", {
        loop: false, // 循环模式选项
        slidesPerView: 4, // 根据卡片的宽度自动调整每屏显示的卡片数量
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //导航器
        autoplay: false,
        // 其他配置项...
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
    initImgSwiper2() {
      new Swiper("#imgSwiper2", {
        loop: false, // 循环模式选项
        slidesPerView: 4, // 根据卡片的宽度自动调整每屏显示的卡片数量
        autoHeight: "true", //开启自适应高度,容器高度由slide高度决定
        //导航器
        autoplay: false,
        // 其他配置项...
        navigation: {
          nextEl: "#btn-next",
          prevEl: "#btn-prev",
        },
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pod|iPhone|iPod|ios|Android|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async getList() {
      this.heartLeftTop = [];
      this.heartLeftBottom = [];
      this.heartRightTop = [];
      this.heartRightBottom = [];
      this.previewList = [];
      this.previewList2 = [];
      this.previewList3 = [];
      this.previewList4 = [];
      let res = await this.$http.get(this.$api.partyDetail, {
        module: "1",
        status: "1",
      });
      this.desc = res.data.data ? res.data.data.remark : "";
      let res2 = await this.$http.post(this.$api.listPartySlideshow, {
        module: "1",
        status: "1",
      });
      if (res2.data.data && res2.data.data.length > 0) {
        let tempData = res2.data.data;
        for (let i in tempData) {
          if (tempData[i].location == "1") {
            //左上
            this.heartLeftTop.push(tempData[i]);
            this.previewList.push(tempData[i].imgUrl);
          } else if (tempData[i].location == "2") {
            //左下
            this.heartLeftBottom.push(tempData[i]);
            this.previewList2.push(tempData[i].imgUrl);
          } else if (tempData[i].location == "3") {
            //右上
            this.heartRightTop.push(tempData[i]);
            this.previewList3.push(tempData[i].imgUrl);
          } else {
            //右下
            this.heartRightBottom.push(tempData[i]);
            this.previewList4.push(tempData[i].imgUrl);
          }
        }
      }
      this.$nextTick(() => {
        // 获取善心轮播图
        this.detail11();
        this.detail12();
        this.detail13();
        this.detail14();
      });
    },
    async getList2() {
      this.previewList5 = [];
      let res = await this.$http.get(this.$api.partyDetail, {
        module: "2",
        status: "1",
      });
      this.desc2 = res.data.data ? res.data.data.remark : "";
      let res2 = await this.$http.post(this.$api.listPartySlideshow, {
        module: "2",
        status: "1",
      });
      if (res2.data.data && res2.data.data.length > 0) {
        this.learnings = res2.data.data || [];
        for (let i in this.learnings) {
          this.previewList5.push(this.learnings[i].imgUrl);
        }
      }
      this.$nextTick(() => {
        this.initImgSwiper();
      });
    },
    async getList3() {
      this.moveLeftTop = [];
      this.moveLeftBottom = [];
      this.moveRightTop = [];
      this.moveRightBottom = [];
      this.previewList6 = [];
      this.previewList7 = [];
      this.previewList8 = [];
      this.previewList9 = [];
      let res = await this.$http.get(this.$api.partyDetail, {
        module: "3",
        status: "1",
      });
      this.desc3 = res.data.data ? res.data.data.remark : "";
      let res2 = await this.$http.post(this.$api.listPartySlideshow, {
        module: "3",
        status: "1",
      });
      if (res2.data.data && res2.data.data.length > 0) {
        let tempData = res2.data.data;
        for (let i in tempData) {
          if (tempData[i].location == "1") {
            //左上
            this.moveLeftTop.push(tempData[i]);
            this.previewList6.push(tempData[i].imgUrl);
          } else if (tempData[i].location == "2") {
            //左下
            this.moveLeftBottom.push(tempData[i]);
            this.previewList7.push(tempData[i].imgUrl);
          } else if (tempData[i].location == "3") {
            //右上
            this.moveRightTop.push(tempData[i]);
            this.previewList8.push(tempData[i].imgUrl);
          } else {
            //右下
            this.moveRightBottom.push(tempData[i]);
            this.previewList9.push(tempData[i].imgUrl);
          }
        }
      }
      this.$nextTick(() => {
        // 获取善行轮播图
        this.detail21();
        this.detail22();
        this.detail23();
        this.detail24();
      });
    },
    async getList4() {
      this.previewList10 = [];
      let res = await this.$http.get(this.$api.partyDetail, {
        module: "4",
        status: "1",
      });
      this.desc4 = res.data.data ? res.data.data.remark : "";
      let res2 = await this.$http.post(this.$api.listPartySlideshow, {
        module: "4",
        status: "1",
      });
      if (res2.data.data && res2.data.data.length > 0) {
        this.newsSwipers = res2.data.data || [];
        for (let i in this.newsSwipers) {
          this.previewList10.push(this.newsSwipers[i].imgUrl);
        }
      }

      this.$nextTick(() => {
        this.initImgSwiper2();
      });
    },
    toPage(row) {
      // console.log(row);
      // this.$toast('敬请期待');
      // return
      sessionStorage.setItem("conid", row.id);
      this.$router.push({
        name: "recruiting",
        params: {
          id: row.id,
        },
      });
    },
    clickNav(index) {
      this.firstIndex = index;
      var navPage = document.querySelector(".right_box" + index);
      // const wrap=this.sysWrap.wrap;
      // wrap.scrollTop=navPage.offsetTop;
      window.scrollTo({
        top: navPage.offsetTop,
        behavior: "smooth",
      });
    },
    handler({ BMap, map }) {
      var point = new BMap.Point(111, 111);
      map.centerAndZoom(point, 19);
      var marker = new BMap.Marker(point); // 创建标注
      map.addOverlay(marker); // 将标注添加到地图中
      var circle = new BMap.Circle(point, 1, {
        strokeColor: "Red",
        strokeWeight: 1,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });
      map.addOverlay(circle);
    },
    getClickInfo(e) {
      console.log(e.point.lng);
      console.log(e.point.lat);
      this.center.lng = e.point.lng;
      this.center.lat = e.point.lat;
    },

    readDetail() {
      this.$router.replace({ path: "/help2" });
    },
    goHelpMore(i) {
      console.log(i);
      this.$router.replace({ path: "/help1?index=" + i });
    },
    wallet() {
      var i = 0;
      var a = setInterval(() => {
        if (typeof window.ethereum !== "undefined") {
          connectTron().then(
            (info) => {
              clearInterval(a);
              if (info[0].indexOf("0x") == 0) {
                if (info[0] != this.$store.getters.userWallet) {
                  this.buy = true;
                }
                this.$store.commit("userWallet", info[0]);
              } else {
                this.$toast("Use the ETH address to log in to the game");
              }
            },
            (e) => {
              this.$toast(e.message);
            }
          );
        }
        if (i > 5) {
          clearInterval(a);
        }
        i++;
      }, 1000);
    },

    tobuy() {
      location.href = "https://opensea.io/collection/cupid-love-metaverse";
    },
    up() {
      if (this.sqloading) {
        return;
      }
      this.buynum = Number(this.buynum) + 1;
    },
    dow() {
      if (this.sqloading) {
        return;
      }
      this.buynum = this.buynum <= 1 ? 1 : Number(this.buynum) - 1;
    },
    close() {
      this.shows = false;
    },
    handleFiexdToolItem(e) {
      if (!e) {
        return;
      }
      this.$nextTick(() => {
        document.getElementById(e).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
$t-mf: "Arial";

.main {
  background: #ffffff;
  min-height: 100vh;
  max-width: 100%;
  min-width: 1200px;
  width: 100%;

  .htop {
    height: 64px;
  }

  .banner {
    width: 100%;
    font-size: 0;

    .simg {
      width: 100%;
      // height: 695px;
      object-fit: contain;
    }

    :deep(.swiper-pagination-bullets) {
      // left: -35%;
      bottom: 100px;
      display: flex;
      width: 1200px;
      left: 0;
      right: 0;
      margin: auto;
    }

    :deep(.swiper-pagination-bullet) {
      width: 36px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      display: inline-block;
      border-radius: 0%;
    }

    :deep(.swiper-pagination-bullet-active) {
      width: 36px;
      height: 3px;
      opacity: 1;
      // background: #3A89FE;
      background: #ffffff;
      position: relative;
    }

    :deep(.swiper-pagination-bullet-active)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
      height: 3px;
      background: #3a89fe;
      /* 覆盖层颜色 */
      z-index: 1;
      animation: revealColor 5s forwards;
      /* 动画名称、持续时间和填充模式 */
    }

    @keyframes revealColor {
      from {
        width: 0%;
        /* 动画结束时，覆盖层宽度为0，暴露出下面的颜色 */
      }
    }
  }

  // .banner {
  // 	max-width: 100%;
  // 	min-width: 1200px;
  // 	width: 100%;
  // 	margin-bottom: 40px;
  // 	.banimg {
  // 		max-width: 100%;
  // 		min-width: 1200px;
  // 		width: 100%;
  // 		height: 510px;
  // 		position: relative;
  // 		text-align: left;
  // 		background: url("../assets/ximg/banner3.jpg");
  // 		background-size: cover;
  // 		display: flex;
  // 		flex-direction: column;
  // 		align-items: center;
  // 		justify-content: center;
  // 		.title {
  // 			width: 1200px;
  // 			font-size: 36px;
  // 			font-family: Microsoft YaHei;
  // 			font-weight: 400;
  // 			color: #ffffff;
  // 		}
  // 		.tip {
  // 			width: 1200px;
  // 			font-size: 16px;
  // 			font-family: Microsoft YaHei;
  // 			font-weight: 400;
  // 			color: #ffffff;
  // 			margin-top: 23px;
  // 		}
  // 	}
  // }
  .zbbg {
    height: 1007px;
    box-sizing: border-box;
    background: url(~@/assets/sdImages/party/Group1594.png) no-repeat !important;
    background-size: 100% 100% !important;
    background-position: center center;

    .boxzb {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .boxzb-left {
        width: 793px;
        height: 440px;
        border-radius: 8px 8px 8px 8px;

        .swiper-container > .swiper-wrapper > .swiper-slide {
          height: 440px;
          position: relative;

          img {
            object-fit: cover;
          }

          .swiper-pagination-text {
            display: flex;
            position: absolute;
            bottom: 0;
            z-index: 999;
            width: 792px;
            background: rgb(148, 29, 30, 0.9);
            padding: 20px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;

            .swiper-pagination-text-left {
              margin-right: 20px;
              margin-left: 33px;

              .num {
                height: 30px;
                //   font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 32px;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                text-transform: none;
                margin-bottom: 10px;
              }

              .time {
                //   font-family: PingFang SC, PingFang SC;
                font-weight: 400;
                font-size: 14px;
                color: #ffffff;
                text-align: center;
                font-style: normal;
                text-transform: none;
              }
            }

            .swiper-pagination-text-right {
              width: 446px;
              // font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              font-size: 18px;
              color: #fbfbfb;
              line-height: 30px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
          }
        }

        .swiper-pagination {
          bottom: 0;
          // background: #941d1e;
          opacity: 0.9;
          height: 87px;
          display: flex;
          align-items: center;
          justify-content: end;

          :deep(.swiper-pagination-bullet) {
            width: 8px;
            height: 8px;
            background: #ffffff;
            border-radius: 100%;
            transform: translateX(-30px) translateY(-2px);
          }

          :deep(.swiper-pagination-bullet-active) {
            border: 2px solid #fbfbfb;
            background: #941d1e;
          }
        }
      }

      .boxzb-right {
        width: 391px;
        height: 440px;
        background: #ffffff;
        border-bottom: 4px solid #851f2b;
        padding-top: 18px;
        box-sizing: border-box;

        .boxzb-right-list-box {
          height: 380px;
          overflow-y: auto;
        }

        .boxzb-right-list {
          width: 90%;
          height: 46px !important;
          border-bottom: 1px solid #f4f4f4;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .content {
            // font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            font-size: 14px;
            color: #0f0e0f;
            font-style: normal;
            text-transform: none;
          }

          .time {
            // font-family: PingFang TC, PingFang TC;
            font-weight: 400;
            font-size: 14px;
            color: #8d8f94;
            font-style: normal;
            text-transform: none;
          }
        }

        .boxzb-right-btn {
          display: flex;
          justify-content: space-between;

          .up {
            width: 40%;
            text-align: center;
            color: #941d1e;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
          }

          .line {
            height: 20px;
            margin-top: 10px;
            border: 1px solid #eeeeee;
          }

          .down {
            width: 40%;
            text-align: center;
            color: #941d1e;
            font-size: 24px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }

      .boxzb-bottom {
        margin-top: 16px;
        width: 389px;
        height: 286px;

        .boxzb-bottom-img {
          width: 100%;
          height: 220px;
          overflow: hidden;

          img {
            width: 100%;
            height: 220px;
            transition: 0.35s;
            object-fit: cover;
          }

          img:hover {
            transform: scale(1.1);
          }
        }

        .boxzb-bottom-text {
          width: 100%;
          height: 66px;
          //   font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          line-height: 66px;
          text-align: center;
          font-style: normal;
          text-transform: none;
          background: #ffffff;
        }
      }
    }
  }

  .abg-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 77px;
    box-sizing: border-box;

    .abg-nav {
      // border: 1px solid #e8e9ea;
      height: 77px;
      margin-right: 23px;
      padding: 0 45px;
      // font-family: PingFang SC, PingFang SC;
      line-height: 77px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      font-style: normal;
      text-transform: none;
      cursor: pointer;
      box-sizing: border-box;
    }

    .active {
      background: #bd2b2f;
      // font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      font-style: normal;
      text-transform: none;
      height: 77px;
      line-height: 77px;
    }
  }

  .some-line {
    width: 100%;
    border: 1px solid #8d8f94;
    opacity: 0.2;
    // margin-top: 26px;
  }

  .ss-box {
    height: 930px;
  }

  .jianjie {
    padding: 0 0 70px 0 !important;

    .btop {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 70px auto 60px auto;
      width: 1200px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 36px;
        color: #2b2c2f;
        font-style: normal;
        text-transform: none;
        margin-bottom: 60px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #242222;
        line-height: 31px;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .shanxing {
    padding: 70px 0 70px 0 !important;
    background: #ffffff !important;
    height: 1067px;
    box-sizing: border-box;
    .btop {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 60px auto;
      width: 1200px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 36px;
        color: #2b2c2f;
        font-style: normal;
        text-transform: none;
        margin-bottom: 60px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #242222;
        line-height: 31px;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .main_box {
    width: 100%;
    background: linear-gradient(180deg, #fbf1e1 0%, #ffffff 54%, #fcf2e3 100%);
    padding: 70px 0;

    // box-sizing: border-box;
    // padding: 32px 28px;
    // display: flex;
    // flex-direction: column;
    // text-align: left;
    // cursor: default;
    .dj-title {
      text-align: center;
      margin: 0px auto 60px auto;
      //   font-family: Inter, Inter;
      font-weight: bold;
      font-size: 32px;

      font-style: normal;
      text-transform: none;

      .title-red {
        color: #e50112;
      }

      .title-black {
        color: #2b2c2f;
      }
    }

    .mbg {
      width: 100%;
      height: auto;
    }

    .box1 {
      // margin-top: 45px;
      width: 1200px;
      box-sizing: border-box;
      margin: 0 auto;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .swiper-container {
        margin-bottom: 24px;
        width: 588px;
        height: 323px;
      }

      .swiper-container > .swiper-wrapper > .swiper-slide {
        position: relative;

        .el-image {
          width: 588px;
          height: 323px;
        }

        // img {
        //   width: 588px;
        //   height: 323px;
        //   object-fit: cover;
        // }

        .swiper-pagination-text {
          position: absolute;
          bottom: 0;
          z-index: 999;
          width: 588px;
          background: rgb(22, 22, 22, 0.6);
          line-height: 36px;
          height: 36px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          font-style: normal;
          text-transform: none;
          padding-left: 28px;
          box-sizing: border-box;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }

      .swiper-pagination {
        bottom: 0;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: end;

        :deep(.swiper-pagination-bullet) {
          width: 8px;
          height: 8px;
          background: #ffffff;
          border-radius: 100%;
          transform: translateX(-30px) translateY(-2px);
        }

        :deep(.swiper-pagination-bullet-active) {
          border: 2px solid #fbfbfb;
          background: rgb(22, 22, 22, 0.1);
        }
      }
    }

    .box4 {
      display: flex;
      margin-top: 45px;
      justify-content: center;
      width: 1200px;
      margin: 80px auto;
      align-items: center;
      flex-direction: column;

      // background-color: #f5f6fa;
      .right_title {
        font-size: 28px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .right_tip {
        font-size: 14px;
        // font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }

      .box_cont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 36px;

        .img {
          width: 280px;
          height: 186px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #dcdee0;
          margin: 12px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 250px;
            height: 166px;
          }
        }
      }
    }
  }

  .ts-box {
    background: url(~@/assets/sdImages/party/image164.png) no-repeat !important;
    background-size: 100% 100% !important;
    background-position: center center;
    height: 733px !important;

    .btop2 {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0px auto 20px;
      width: 1200px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 36px;
        color: #2b2c2f;
        font-style: normal;
        text-transform: none;
        margin-bottom: 60px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #242222;
        line-height: 31px;
        font-style: normal;
        text-transform: none;
      }
    }

    .box-ts {
      width: 100%;
      margin: 0 auto;

      // display: flex;
      // align-items: center;
      // justify-content: space-around;
      .left-flag {
        display: flex;
        justify-content: start;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }

      .center-content {
        // font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        line-height: 39px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .right-flag {
        display: flex;
        justify-content: end;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }

      .ts-img {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .img {
          width: 286px;
          height: 200px;
          overflow: hidden;
          border-radius: 8px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            transition: 0.35s;
          }
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      .item {
        display: flex;
        align-items: flex-start;

        .img {
          img {
            width: 129px;
            height: 129px;
          }
        }

        .cont {
          margin-left: 54px;

          .title {
            font-size: 24px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1d2129;
            line-height: 33px;
            text-align: left;
            margin-bottom: 24px;
          }

          .txt {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #646566;
            text-align: left;
          }
        }
      }

      .out-swiper-container {
        position: relative;
        margin-top: 55px;
      }

      .swiper-container1 {
        position: initial;
      }

      .swiper-wrapper {
        justify-content: space-between;
      }

      .swiper-container1 > .swiper-wrapper > .swiper-slide {
        height: 323px;
        width: 24% !important;
        position: relative;

        .el-image {
          width: 100%;
          height: 100%;
        }

        // img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        // }
      }

      .swiper-pagination-text {
        position: absolute;
        bottom: 0;
        z-index: 999;
        width: 100%;
        background: rgb(22, 22, 22, 0.6);
        line-height: 36px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      /* 自定义按钮样式 */
      .swiper-button-next {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group12370@2x.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group12373@2x.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group12371@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group12372@2x.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next::after {
        font-size: 0;
      }

      .swiper-button-prev::after {
        font-size: 0;
      }

      .swiper-button-prev {
        top: 375px;
        left: 46%;
      }

      .swiper-button-next {
        top: 375px;
        right: 46%;
      }

      /* 鼠标悬浮时改变样式 */
      .swiper-button-next:hover,
      .swiper-button-prev:hover {
        opacity: 1;
        /* 更改悬浮时的透明度 */
      }
    }
  }

  .about_box {
    width: 100%;
    height: 734px;
    background: #bd1f24;
    // 	background-image: url(~@/assets/sdImages/party/Group1571.png);
    // background-position: 100%;
    // background-size: 100% 100%;
    // background-repeat: no-repeat;

    padding: 70px 0 110px 0;
    box-sizing: border-box;

    .btop {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0px auto 20px;
      width: 1200px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: Inter, Inter;
        font-weight: bold;
        font-size: 36px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        margin-bottom: 60px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        line-height: 31px;
        font-style: normal;
        text-transform: none;
      }
    }

    .box2 {
      width: 100%;
      margin: 0 auto;

      // display: flex;
      // align-items: center;
      // justify-content: space-around;
      .left-flag {
        display: flex;
        justify-content: start;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }

      .center-content {
        // font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        line-height: 39px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .right-flag {
        display: flex;
        justify-content: end;
        // font-family: Plus Jakarta Sans, Plus Jakarta Sans;
        font-weight: 500;
        font-size: 128px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        height: 68px;

        img {
          width: 36px;
          height: 32px;
        }
      }

      .ts-img {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .img {
          width: 286px;
          height: 200px;
          overflow: hidden;
          border-radius: 8px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            transition: 0.35s;
          }
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      .item {
        display: flex;
        align-items: flex-start;

        .img {
          img {
            width: 129px;
            height: 129px;
          }
        }

        .cont {
          margin-left: 54px;

          .title {
            font-size: 24px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1d2129;
            line-height: 33px;
            text-align: left;
            margin-bottom: 24px;
          }

          .txt {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #646566;
            text-align: left;
          }
        }
      }

      .out-swiper-container {
        position: relative;
        margin-top: 55px;
      }

      .swiper-container1 {
        position: initial;
      }

      .swiper-wrapper {
        justify-content: space-between;
      }

      .swiper-container1 > .swiper-wrapper > .swiper-slide {
        height: 323px;
        width: 24% !important;
        position: relative;

        .el-image {
          width: 100%;
          height: 100%;
        }

        // img {
        //   width: 100%;
        //   height: 100%;
        //   object-fit: cover;
        // }
      }

      .swiper-pagination-text {
        position: absolute;
        bottom: 0;
        z-index: 999;
        width: 100%;
        background: rgb(22, 22, 22, 0.6);
        line-height: 36px;
        height: 36px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        font-style: normal;
        text-transform: none;
        text-align: center;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }

      /* 自定义按钮样式 */
      .swiper-button-next {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group1606.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev {
        width: 42px;
        height: 42px;
        background: url(~@/assets/sdImages/party/Group1607.png) no-repeat;
        background-size: 100% 100%;
        /* 设置背景颜色 */
        opacity: 1;
        color: #cccccc;
        z-index: 99;
      }

      .swiper-button-prev.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group1708.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next.swiper-button-disabled {
        background: url(~@/assets/sdImages/party/Group1605.png) no-repeat;
        background-size: 100% 100%;
      }

      .swiper-button-next::after {
        font-size: 0;
      }

      .swiper-button-prev::after {
        font-size: 0;
      }

      .swiper-button-prev {
        top: 375px;
        left: 46%;
      }

      .swiper-button-next {
        top: 375px;
        right: 46%;
      }

      /* 鼠标悬浮时改变样式 */
      .swiper-button-next:hover,
      .swiper-button-prev:hover {
        opacity: 1;
        /* 更改悬浮时的透明度 */
      }
    }
  }

  .box3 {
    margin-top: 60px;
    text-align: center;

    .right_title {
      font-size: 28px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

    .right_con {
      display: flex;
      margin-top: 35px;
      justify-content: center;

      img {
        width: 1200px;
      }
    }
  }

  .box6 {
    text-align: center;
    background: #f4f6fe;

    //  margin-top:50px;
    .right_title {
      margin-top: 50px;
      font-size: 28px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }

    .right_tip {
      font-size: 14px;
      //   font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-bottom: 40px;
      // line-height: 60px;
    }

    .box_cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 1226px;
      margin: 0 auto 80px;

      .item {
        width: 280px;
        height: 186px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #dcdee0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
        margin: 13px;

        .title {
          font-size: 18px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 25px;
        }

        .btn {
          min-width: 122px;
          height: 40px;
          background: #ffffff;
          border-radius: 2px;
          font-size: 16px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2b6bff;
          line-height: 40px;
          margin-top: 20px;
          border: 1px solid #2b6bff;
          cursor: pointer;
        }
      }

      .item:hover {
        box-shadow: 0px 6px 16px 0px rgba(62, 73, 96, 0.15);
      }
    }
  }
}

.h5 {
  min-width: 100%;

  .htop {
    height: 125px;
  }

  .banner {
    width: 100%;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .simg {
      width: 1000px;
      height: auto;
    }
  }

  .main_box {
    .box1 {
      width: 100%;
      flex-direction: column;
      align-items: center;
      padding: 32px 0;
      height: auto;

      .img {
        margin-top: 34px;
        position: static;

        img {
          width: 494px;
          height: 367px;
        }
      }

      .left_con {
        margin: 0 37px;
        width: 100%;

        .title {
          font-size: 36px;
          text-align: center;
          font-weight: bold;
        }

        .cont {
          font-size: 26px;
          line-height: 42px;
          margin: 0 auto 40px;
          width: calc(100% - 64px);
        }
      }
    }

    .box4 {
      width: 100%;

      .right_title {
        font-size: 36px;
        font-weight: bold;
      }

      .right_tip {
        font-size: 26px;
        margin: 10px auto;
      }

      .box_cont {
        padding: 32px 28px;
        width: calc(100% - 56px);

        .img {
          width: calc(50% - 30px);
        }
      }
    }
  }

  .box3 {
    .right_title {
      font-size: 36px;
      font-weight: bold;
    }

    .right_tip {
      font-size: 26px;
      margin: 10px auto;
    }

    .right_con {
      padding: 32px 28px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .about_box {
    .box2 {
      width: 100%;
      flex-direction: column;

      .item {
        width: calc(100% - 60px);
        margin: 0 auto;
        align-items: center;

        .cont {
          .title {
            font-size: 32px;
            margin: 10px 0;
          }

          .txt {
            font-size: 26px;
            height: 36px;
          }
        }
      }
    }
  }

  .box6 {
    .box_cont {
      width: calc(100% - 56px);
      margin: 50px auto;

      .item {
        width: calc(50% - 26px);

        .title {
          font-size: 26px;
          line-height: 42px;
        }

        .btn {
          font-size: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: 0px) {
  .Gtitle {
    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #969799;
      line-height: 22px;
    }
  }

  .enter_box {
    position: relative;
    padding: 30px 0;

    .fzbg {
      display: none;
      width: 100%;
    }

    ul {
      margin-top: 30px;

      li {
        margin-bottom: 40px;
        padding-left: 60px;
        position: relative;
        z-index: 1;

        &:last-child {
          &::before {
            display: none;
          }
        }

        &::after {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          position: absolute;
          left: 10px;
          top: 0px;
          background-color: #f2f6fe;
          z-index: -1;
        }

        &::before {
          content: "";
          width: 1px;
          height: calc(100% + 20px);
          position: absolute;
          left: 25px;
          top: 0px;
          background-color: #2b6bff;
          z-index: -1;
        }

        h4 {
          font-size: 18px;
          font-weight: 500;
          color: #1d2129;

          &::after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 20px;
            top: 10px;
            background-color: #2b6bff;
          }
        }

        p {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #646566;
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
}

@media screen and (min-width: 820px) {
}

@media screen and (min-width: 1100px) {
  .Gtitle {
    h1 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      margin-top: 14px;
    }
  }

  .enter_box {
    padding: 0;
    margin-top: 80px;

    .Gtitle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }

    ul {
      display: none;
    }

    .fzbg {
      display: block;
    }
  }
}

@media screen and (min-width: 1300px) {
}
</style>
